@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600;700;800;900&family=Labrada:wght@100;200;300;400;500&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Roboto:wght@400;500;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
.active{
    @apply  hover:bg-gray-600 text-white ;
}
.bg-slideshow {
  position: relative;
  
    width: 100%; /* Adjust the width as needed */
    height: 100%; /* Adjust the height as needed */
  }
  
  .bg-slideshow::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('https://stimg.cardekho.com/images/carexteriorimages/630x420/Hyundai/Creta/7695/1651645683867/front-left-side-47.jpg'); /* Replace with your Hyundai car image URLs */
    background-size: cover;
    background-position: center;
    animation: bg-slideshow-animation 10s infinite;
    opacity: 1; /* Set initial opacity to 1 to keep the image always visible */
  }
  
  @keyframes bg-slideshow-animation {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    33.33% {
      opacity: 1;
      background-image: url('https://stimg.cardekho.com/images/carexteriorimages/630x420/Hyundai/Verna/8703/1679389180298/front-left-side-47.jpg'); /* Replace with your Hyundai car image URLs */
    }
    53.33% {
      opacity: 1;
      background-image: url('https://stimg.cardekho.com/images/carexteriorimages/630x420/Hyundai/Verna/8703/1679389180298/front-left-side-47.jpg');
    }
    100% {
      opacity: 1;
    }
  }
  .mySwiper .swiper-pagination {
    bottom: 20px; /* Adjust the distance from the bottom as needed */
  }
 